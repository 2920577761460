const userInstagram = require("user-instagram");
window.addEventListener("DOMContentLoaded", () => {
  userInstagram("fabiangroher")
    .then((data) => {
      const element = document.getElementById("app");
      const fragment = document.createDocumentFragment();
      document.title = data.biography;
      data.posts.forEach((post) => {
        const img = document.createElement("img");
        img.src = post.imageUrl;
        img.alt = `📍 ${post.location.name}: ${post.caption} (${post.likesCount})`;
        img.title = img.alt;
        img.width = post.dimensions.width;
        img.height = post.dimensions.height;
        img.setAttribute("loading", "lazy");
        fragment.appendChild(img);
      });

      element.appendChild(fragment);
    })
    .catch(console.error);
});
